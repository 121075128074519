<template>
    <div class="gifting">
        <div class="gifting_flex">
            <div class="gifting_left">
                <h4 class="font-active">Coming Together</h4>
                <span >Through the Joy of Gifting</span>
                <p>Discover timeless treasures for everyone on your list.</p>
                <div class="anker_flex">
                    <router-link :to="localePath({ name:'shop-all', query: { 'RefreshKey': 'all' } })">SHOP GIFTS</router-link>
                    <router-link :to="localePath({ name:'shop-all',  query:{'Shipdate': '5_days', 'RefreshKey': '5_days'} })">Ready to Ship Fine Jewelry</router-link>
                </div>
            </div>
            <div class="gifting_right">
                <video @contextmenu.prevent="" controlsList="nodownload" class="videodiv" loop muted autoplay playsinline width="1057" height="580">
                    <source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/christmas_gift.mp4" type="video/mp4">
                </video>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
video{
	max-width: 100%;
	height: auto;
    border-radius: 8px;
}
.gifting {
    background: #f5f5f5;
    padding: 2% 4%;
}

.gifting_left {
    background-image: radial-gradient(circle at 50% 50%, #8b1414, #570909 100%);
    width: 45%;
    padding: 5% 2% 5% 3%;
    position: relative;
    border-radius: 8px 0 0 8px;
    position: relative;
}

.gifting_left:before {
    position: absolute;
    content: '';
    height: 100%;
    top: 0;
    right: -34px;
    width: 35px;
    background: #5b0a0a;
    border-radius: 0 8px 8px 0;
}
.gifting_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gifting_left h4 {
    color: #fff;
    font-size: 46px;
    text-transform: capitalize;
    margin: 0;
}

.gifting_right {
    width: 55%;
}

.gifting_left span {
    margin: 1% 0 3%;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    display: block;
}

.gifting_left p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 5%;
}

.anker_flex {
    display: flex;
    align-items: center;
}

.anker_flex a {
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #fff;
    border: 1px solid #fff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0 15px;
    border-radius: 4px;
    margin-right: 10px;
    height: 45px;
}
@media(max-width: 1550px){
    .gifting_left{
        padding: 3% 2% 3% 3%;
    }
}
@media(max-width: 1250px){
    .gifting_left h4{
        font-size: 36px;
    }
    .gifting_left span, .gifting_left p{
        font-size: 16px;
    }
}
@media(max-width: 1090px){
    .gifting_flex {
        flex-wrap: wrap;
    }
    .gifting_left {
        width: 100%;
        border-radius: 8px;
        text-align: center;
    }
    .gifting_left p{
        margin-bottom: 20px;
    }
    .gifting_left:before{
        content: none;
    }
    .gifting_right {
        width: 100%;
    }
    .anker_flex{
        justify-content: center;
    }
    .gifting_left h4 {
        margin: 10px 0;
    }
}
@media(max-width: 767px){
    .gifting{
        padding: 4%;
    }
    .gifting_left h4 {
        font-size: 24px;
    }
    .gifting_flex {
        flex-wrap: wrap;
    }
    .gifting_left {
        width: 100%;
        border-radius: 8px 8px 0 0;
    }
    .gifting_right {
        width: 100%;
    }
    .anker_flex {
        flex-wrap: wrap;
    }
    .anker_flex a {
        width: 100%;
        max-width: 100%;
        margin: 5px 0;
    }
    .gifting_left {
        padding: 8%;
    }
    video{
        border-radius: 0 0 8px 8px;
    }
}
</style>